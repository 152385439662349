require('./bootstrap');

// require('alpinejs');

import Alpine from 'alpinejs';


import slug from "alpinejs-slug";


Alpine.plugin(slug);

window.Alpine = Alpine;

Alpine.start();
